import Signal from "./Signal"


export class Mouse extends Signal {

  constructor() {
    super()

    this.isDown = false

    this.ncoords = [0, 0]
    this.coords = [0, 0]

    this.onInputDown = this.onInputDown.bind(this)
    this.onInputMove = this.onInputMove.bind(this)
    this.onInputUp = this.onInputUp.bind(this)

  }

  start() {
    window.addEventListener('mousedown', this.onInputDown)
    window.addEventListener('mousemove', this.onInputMove)
    window.addEventListener('mouseup', this.onInputUp)
  }

  onInputDown(evt) {
    this.isDown = true

    this.ncoords[0] = (evt.pageX/window.innerWidth) * 2 - 1
    this.ncoords[1] =  ((1-(evt.pageY/window.innerHeight)) * 2 - 1)

    this.coords[0] = evt.pageX
    this.coords[1] = evt.pageY

  }

  onInputMove(evt) {
    this.ncoords[0] = (evt.pageX/window.innerWidth) * 2 - 1
    this.ncoords[1] =  ((1-(evt.pageY/window.innerHeight)) * 2 - 1)

    this.coords[0] = evt.pageX
    this.coords[1] = evt.pageY
  }

  onInputUp(evt) {
    this.isDown = false
  }


}