<template>
    <header-vue />
    <router-view :key="$route.fullPath" />
    <grid-helper :cols="12" />
    <wheel />
    <loader />
    <cursor />
</template>

<script>
import "./assets/styles/main.scss";
import initAppMixin from "./mixins/initAppMixin";
import GridHelperVue from "./components/blocks/GridHelper.vue";
import HeaderVue from "./components/sections/Header.vue";
import WheelVue from "./components/blocks/Wheel.vue";
import LoaderVue from "./components/blocks/Loader.vue";
import CursorVue from './components/blocks/Cursor.vue';

export default {
    mixins: [initAppMixin],
    components: {
        "grid-helper": GridHelperVue,
        "header-vue": HeaderVue,
        "wheel": WheelVue,
        "loader": LoaderVue,
        "cursor": CursorVue
    },
};
</script>