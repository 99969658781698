const NOOP = function () { }
const isDev = false

let gui = {
  addFolder: () => { return { addFolder: NOOP, addInput: NOOP } },
  addInput: NOOP,
  on: NOOP
}

if (isDev) {
  const plane = require('tweakpane')
  gui = new plane.Pane()
}

gui.isDev = isDev
export default gui
