import * as THREE from 'three'
// console.log(frag)
class MatFactory {

  constructor(scene) {

    this.scene = scene

    this.texs = scene.texs

    this.mats = []

    const cvs = document.createElement('canvas')
    const ctx = cvs.getContext('2d')
    cvs.width = 1024
    cvs.height = 1024
    cvs.style.width = '1024px'
    cvs.style.height = '1024px'

    // get initial texture
    const img = this.scene.texs.get('bottom_map').image
    ctx.drawImage(img, 0, 0)
    // draw over whatever I need
    ctx.fillStyle = 'red'
    ctx.rect(256, 256, 512, 512)
    ctx.fill()

    var canvasMap = new THREE.Texture(cvs)
    // canvasMap.wrapS = THREE.RepeatWrapping;
    // canvasMap.wrapT = THREE.RepeatWrapping;
    canvasMap.needsUpdate = true


    // tongue
    let m = new THREE.MeshStandardMaterial({
      map: this.scene.texs.get('tongue_basecolor'),
      normalMap: this.scene.texs.get('tongue_normal'),
      roughnessMap: this.scene.texs.get('tongue_roughness'),
      color: 0x333333,
      envMap: this.scene.envMap,
      envMapIntensity: 0.1,
      side: THREE.DoubleSide,
      transparent: true,
      //  opacity: .5
    })
    this.registerMaterial('tongue', m)

    // bottom
    m = new THREE.MeshStandardMaterial({
      map: this.scene.texs.get('bottom_map'),
      normalMap: this.scene.texs.get('bottom_normal'),
      roughnessMap: this.scene.texs.get('bottom_roughness'),
      metalnessMap: this.scene.texs.get('bottom_metalness'),
      // roughness: 0.5,
      // metalness: 1,
      color: 0xffffff,
      envMap: this.scene.envMap,
      envMapIntensity: 0.1,
      // side: THREE.DoubleSide
    })
    this.registerMaterial('bottom', m)

  }


  registerMaterial(name, material) {
    this.mats[name] = material
  }

  makeMaterial(name) {

    const tAlbedo = this.texs.get(`${name}_BaseColor`)
    const tNormal = this.texs.get(`${name}_Normal`)
    const tRoughness = this.texs.get(`${name}_Roughness`)
    // const tMetalness = this.texs.get(`${name}_Metalness`)


    const m = new THREE.MeshStandardMaterial({
      map: tAlbedo,
      normalMap: tNormal,
      roughnessMap: tRoughness,
      metalness: .9,
      // metalnessMap: tMetalness,
      transparent: false,
      envMap: this.scene.envMap,
      envMapIntensity: .05,
      side: THREE.DoubleSide,
    })

    m.needsUpdate = true

    return m

  }

  get(name) {
    if (this.mats[name])
      return this.mats[name]

    console.error('[MatFactory get] no material registered :', name)
    return null
  }

}

export default MatFactory