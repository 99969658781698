import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    isFontsLoaded: false,
    isDataLoaded: false,
    isImagesLoaded: false,
    activeVimeoId: '',
    scrollUpdate: 0,
    isPageEntering: false,
    pageEnteringDuration: 300,
    pageLeavingDuration: 300,
};

const store = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

export default store;
