import * as THREE from 'three'

function NOOP() { }

class TextureProvider {

  constructor() {

    this.texs = {}
    this.manifest = []
    this.texsLoaded = 0

    this.loader = new THREE.TextureLoader()

    this.res = null

  }

  load(manifest) {

    this.manifest = manifest

    this.manifest.forEach(entry => {
      this.loadTex(entry.id, entry.url)
    })

    return new Promise((resolve) => {
      this.res = resolve
    })

  }

  loadTex(id, url) {


    this.loader.load(
      url,
      // success
      texture => {
        this.onTexLoaded(id, texture)
        // resolve()
      },
      // progress
      NOOP,
      // error
      (evt) => { console.log(evt) }
    )
  }

  onTexLoaded(id, texture) {
    this.texs[id] = texture

    texture.flipY = false

    this.texsLoaded++
    if (this.texsLoaded == this.manifest.length) {
      console.log('all texs loaded')
      this.res()
    }

  }

  get(name) {
    if (this.texs[name])
      return this.texs[name]

    console.error('[TextureProvider get] no texture named', name)
    return null
  }

}

export default TextureProvider
