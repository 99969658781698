const mutations = {
    SET_WIDTH: (state, val) => {
        state.width = val;
    },
    SET_HEIGHT: (state, val) => {
        state.height = val;
    },
    SET_DEVICE_PIXEL_RATIO: (state, val) => {
        state.devicePixelRatio = val;
    }
};

export default mutations;
