<template>
    <div class="c-cursor" :class="[`-${cursorState}`]">
        <div class="c-cursor_state -default"></div>
        <div class="c-cursor_state -hover"></div>
        <div class="c-cursor_state -load"></div>
        <div class="c-cursor_state -close"></div>
    </div>
</template>

<script>
import Raf from "quark-raf";
import { mapActions, mapState } from 'vuex';
import isMobile from "../../utils/isMobile";

// Get is mobile state
const isMobileObj = isMobile()
const IS_TOUCH_DEVICE = isMobileObj?.phone || isMobileObj?.tablet

export default {
    name: "Cursor",

    computed: {
        ...mapState("cursor", ["cursorState"]),
    },

    created() {
        this.translate = {
            x: window.innerWidth * 0.5,
            y: window.innerHeight * 0.5,
            smoothX: window.innerWidth * 0.5,
            smoothY: window.innerHeight * 0.5,
            lerp: 0.08,
        };

        this.setCursorCoords({
            x: this.translate.x,
            y: this.translate.y
        })

        this.isPlaying = false;

        this.onUpdateBind = this.onUpdate.bind(this);
        this.onMouseMoveBind = this.onMouseMove.bind(this);
    },

    mounted() {        
        this.translateItem(this.translate.x, this.translate.y);
        
        if (IS_TOUCH_DEVICE) {
            return
        }

        this.bindEvents();

        this.$nextTick(() => {
            this.play();
        });
    },

    beforeUnmount() {
        this.unbindEvents();
    },

    methods: {
        ...mapActions("cursor", ['setCursorCoords']),

        bindEvents() {
            window.addEventListener("mousemove", this.onMouseMoveBind);
        },

        unbindEvents() {
            window.removeEventListener("mousemove", this.onMouseMoveBind);
        },

        play() {
            if (this.isPlaying) return;
            this.isPlaying = true;
            Raf.add(this.onUpdateBind);
        },

        stop() {
            if (!this.isPlaying) return;
            this.isPlaying = false;
            Raf.remove(this.onUpdateBind);
        },

        onUpdate() {
            this.translate.smoothX +=
                (this.translate.x - this.translate.smoothX) *
                this.translate.lerp;
            this.translate.smoothX =
                ((100 * (this.translate.smoothX + 0.01)) | 0) / 100;

            this.translate.smoothY +=
                (this.translate.y - this.translate.smoothY) *
                this.translate.lerp;
            this.translate.smoothY =
                ((100 * (this.translate.smoothY + 0.01)) | 0) / 100;

            this.translateItem(this.translate.smoothX, this.translate.smoothY);
        },

        onMouseMove(e) {
            this.translate.x = e.clientX;
            this.translate.y = e.clientY;

            this.setCursorCoords({
                x: this.translate.x,
                y: this.translate.y
            })
        },

        translateItem(x, y) {
            this.$el.style.transform = `translate3d(${x}px, ${y}px, 0)`;
        },
    },
};
</script>