<template>
    <div class="c-header">
        <div class="c-header_inner || o-container">
            <router-link
                class="c-header_logo"
                to="/"
                aria-label="Back to homepage"
                data-cursor='hover'
            >   
                <span ref="logo"></span>
                <svg
                    class="svg-logo"
                    role="img"
                    aria-hidden="true"
                    focusable="false"
                >
                    <use xlink:href="@/assets/svg/logo.svg"></use>
                </svg>
                <span class="u-screen-reader-text">Mu Design</span>
            </router-link>
            <menu-vue></menu-vue>
        </div>
    </div>
</template>

<script>
import lottie from 'lottie-web'
import MenuVue from '../blocks/Menu.vue';
import animation from "../../assets/lottie/logo-dark.json"

export default {
    name: "Header",
    components: {
        'menu-vue': MenuVue
    },
    data() {
        return {
            isActive: false,
        };
    },
    created() {

    },
    mounted() {
        //this.initLottie()
    },
    methods: {
        initLottie() {
            lottie.loadAnimation({
                container: this.$refs.logo, // the dom element that will contain the animation
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: animation // the path to the animation json
            });
        }
    },
};
</script>