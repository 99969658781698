import { map } from './utils/math'
import anime from 'animejs'

import gui from "./dev/gui"
import metrics from '../store/metrics'


const mouse = { x: 0, y: 0 }

const cfg = {
  position: { x: 0, y: 0.2, z: 7 },
  parallax: {
    speed: .1,
    amplitudeX: .025,
    amplitudeY: .025
  },
  pola: {
    main: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 }
    },
    rxNode: {
      rotation: { x: 0, y: 0, z: 0 }
    },
    ryNode: {
      rotation: { x: 0, y: 0, z: 0 }
    }
  }
}


class CamCtrl {

  constructor(scene) {

    this.scene = scene
    this.camera = scene.camera
    this.object = scene.packs.instances[0].node

    this.rxNode = this.object.children[0]
    this.ryNode = this.object.children[0].children[0]

    this.ryNode.rotation.y = cfg.pola.ryNode.rotation.y

    this.camera.position.set(0, 0, 25);

    this.influence = 0

    this.onMouseMove = this.onMouseMove.bind(this)

    this.addListeners()

    // this.gui()

    this.debugUpdateCamPos()

  }

  addListeners() {
    window.addEventListener('mousemove', this.onMouseMove)
    // window.addEventListener('mousedown', this.onMouseDown.bind(this))
    // window.addEventListener('mouseup', this.onMouseUp.bind(this))
  }

  removeListeners() {
    window.removeEventListener('mousemove', this.onMouseMove)
  }



  onMouseMove(evt) {
    mouse.x = evt.screenX / metrics.state.width
    mouse.y = evt.screenY / metrics.state.height

  }



  update() {
    // const rx = map(mouse.y, 0.2, .8, -.3, .3 ) + cfg.pola.rxNode.rotation.x
    // const ry = map(mouse.x, 0.2, .8, -.1, .1) + cfg.pola.ryNode.rotation.y
    const rx = map(mouse.y, 0.2, .8, -.15, .15) * this.influence + cfg.pola.rxNode.rotation.x
    const ry = map(mouse.x, 0.2, .8, -.1, .1) * this.influence + cfg.pola.ryNode.rotation.y
    // const camx = map(mouse.x, 0, 1, -cfg.parallax.amplitudeX, cfg.parallax.amplitudeX)
    // const camy = map(mouse.y, 0, 1, .2 - cfg.parallax.amplitudeY, .2 + cfg.parallax.amplitudeY)

    // this.object.rotation.x = cfg.pola.main.rotation.x
    // this.object.rotation.y = cfg.pola.main.rotation.y
    // this.object.rotation.z = cfg.pola.main.rotation.z
    this.rxNode.rotation.x += (rx - this.rxNode.rotation.x) * .025
    this.ryNode.rotation.y += (ry - this.ryNode.rotation.y) * .025

    // this.object.position.x = cfg.pola.main.position.x
    // this.object.position.y = cfg.pola.main.position.y
    // this.object.position.z = cfg.pola.main.position.z

    // this.camera.position.x += (camx - this.camera.position.x) * cfg.parallax.speed
    // this.camera.position.y += (camy - this.camera.position.y) * cfg.parallax.speed

    // console.log(this.camera.position.z)

  }

  setObject(object) {

    this.object = object

    this.rxNode = this.object.children[0]
    this.ryNode = this.object.children[0].children[0]

  }

  gui() {

    this.debugUpdateCamPos = this.debugUpdateCamPos.bind(this)

    const f = gui.addFolder({ title: 'camera', expanded: false })
    const fpos = f.addFolder({ title: `position`, expanded: false })
    fpos.addInput(cfg.position, 'x', { min: -2, max: 2, step: .1 }).on('change', this.debugUpdateCamPos)
    fpos.addInput(cfg.position, 'y', { min: -4, max: 4, step: .1 }).on('change', this.debugUpdateCamPos)
    fpos.addInput(cfg.position, 'z', { min: 2, max: 10, step: .1 }).on('change', this.debugUpdateCamPos)

    const fparallax = f.addFolder({ title: `parralax`, expanded: false })
    fparallax.addInput(cfg.parallax, 'speed', { min: 0.01, max: 0.9, step: .1 })
    fparallax.addInput(cfg.parallax, 'amplitudeX', { min: -2, max: 2, step: .01 })
    fparallax.addInput(cfg.parallax, 'amplitudeY', { min: -2, max: 2, step: .01 })

    const fPola = gui.addFolder({ title: 'pola', expanded: false })

    const fPolaPosition = fPola.addFolder({ title: `position`, expanded: false })
    fPolaPosition.addInput(cfg.pola.main.position, 'x', { min: -3, max: 3, step: .01 })
    fPolaPosition.addInput(cfg.pola.main.position, 'y', { min: -3, max: 3, step: .01 })
    fPolaPosition.addInput(cfg.pola.main.position, 'z', { min: -3, max: 3, step: .01 })

    const fPolaMain = fPola.addFolder({ title: `main node`, expanded: false })
    fPolaMain.addInput(cfg.pola.main.rotation, 'x', { min: -Math.PI, max: Math.PI, step: .01 })
    fPolaMain.addInput(cfg.pola.main.rotation, 'y', { min: -Math.PI, max: Math.PI, step: .01 })
    fPolaMain.addInput(cfg.pola.main.rotation, 'z', { min: -Math.PI, max: Math.PI, step: .01 })

    const fPolaRx = fPola.addFolder({ title: `rx node`, expanded: false })
    fPolaRx.addInput(cfg.pola.rxNode.rotation, 'x', { min: -Math.PI, max: Math.PI, step: .01 })

    const fPolaRy = fPola.addFolder({ title: `ry node`, expanded: false })
    fPolaRy.addInput(cfg.pola.ryNode.rotation, 'y', { min: -Math.PI, max: Math.PI, step: .01 })

  }

  debugUpdateCamPos() {
    this.camera.position.x = cfg.position.x
    this.camera.position.y = cfg.position.y
    this.camera.position.z = cfg.position.z
  }

  transitionIn() {

    // animate z position
    this.camera.position.z = 5
    anime({
      targets: this.camera.position,
      z: 7,
      delay: 100,
      easing: 'easeInOutQuart',
      duration: 4000
    })

    // animate pola rotation
    // cfg.pola.ryNode.rotation.y = 5
    anime({
      targets: cfg.pola.ryNode.rotation,
      y: 0,
      delay: 500,
      easing: 'easeInOutQuart',
      duration: 2000
    })

  }

}

export default CamCtrl