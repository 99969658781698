import * as THREE from 'three'
import { gsap } from 'gsap'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { Packs } from "./entities/pack/packs";
import { FSBackground } from './entities/fsbackground';
import Lights from "./lights";
import MatFactory from "./entities/pack/mat-factory";
import TextureProvider from "./lib/texture-provider";
import CamCtrl from './CamCtrlPacks';
import { Mouse } from './lib/Mouse';
import { Power2, Power4, TweenMax } from "gsap/all";

import { Gauje } from './ui/static-gauje'
import { map } from './utils/math';

let now = Date.now()
let lastTime = now

const DIST_TO_OPEN = 300
const downMousePos = { x: 0, y: 0 }
let mouseTravelledDist = 0
let gaujeProps = { x: 0, y: 0, tgtx: 0, tgty: 0, opacity: 0, tgtOpacity: 0, scale: 1 }

export class ScenePacks {

  constructor(view) {
    this.view = view
    this.isPaused = true

    this.data = view.works

    this.dt = 0

    this.width = view.width
    this.height = view.height

    // renderer
    // const container = document.body
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(this.width, this.height);
    renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer = renderer
    renderer.setClearColor(0x000000, 0); // the default

    // container.appendChild(renderer.domElement);

    // scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xff0000);

    // cam
    this.camera = new THREE.PerspectiveCamera(45, window.innerHeight / window.innerHeight, 0.01, 100)
    this.camera.position.set(0, 0.5, 15);

    // inputs
    this.mouse = new Mouse()
    this.mouse.start()

    // lights
    this.lights = new Lights(this.scene)

    // materials
    this.mats = null
    this.texs = null
    this.envmap = null

    // entities
    this.scene = new THREE.Scene()
    // this.pack = new Pack(this)
    this.packs = new Packs(this)
    this.fsbackground = new FSBackground(this)

    this.tgtMaskProgress = 1
    this.maskProgress = 1

    // gauje
    this.gauje = new Gauje(this.view.$refs.gauje, 80)
    this.gauje.isVisible = true
    this.gauje.fade = 0

    this.isMouseDown = false
    this.isPlayingOpening = false

    this.bind()



  }

  bind() {
    this.onRAF = this.onRAF.bind(this)
    this.transitionOut = this.transitionOut.bind(this)

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
  }

  load() {
    return new Promise(async (resolve) => {

      await this.loadEnv()
      await this.loadTexs()
      // await this.pack.load()
      await this.packs.load()

      this.onLoaded()


      resolve()

    })

  }

  loadEnv() {

    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    pmremGenerator.compileEquirectangularShader();

    return new Promise((resolve) => {
      new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .load('texs/envs/studio_small_07_512.hdr', (texture) => {

          this.envMap = pmremGenerator.fromEquirectangular(texture).texture;

          texture.dispose();
          pmremGenerator.dispose();

          resolve()

        })

    })

  }

  loadTexs() {

    const manifest = []

    manifest.push({ id: 'tongue_basecolor', url: "texs/pack/tongue/base_color.jpg" })
    manifest.push({ id: 'tongue_normal', url: "texs/pack/tongue/normal.jpg" })
    manifest.push({ id: 'tongue_roughness', url: "texs/pack/tongue/roughness.jpg" })

    manifest.push({ id: 'bottom_map', url: "texs/pack/bottom/base_color.jpg" })
    manifest.push({ id: 'bottom_normal', url: "texs/pack/bottom/normal.jpg" })
    manifest.push({ id: 'bottom_roughness', url: "texs/pack/bottom/roughness.jpg" })
    manifest.push({ id: 'bottom_metalness', url: "texs/pack/bottom/metallic.jpg" })

    this.texs = new TextureProvider()

    return new Promise(async (resolve) => {
      await this.texs.load(manifest)
      resolve()
    })

  }

  onLoaded() {

    this.mats = new MatFactory(this)
    this.packs.onSceneLoaded()

    this.camCtrl = new CamCtrl(this)

    // this.pack.onSceneLoaded()
    // this.gltexts.onSceneLoaded()

    // if (process.isClient && window.location.href.indexOf('works') > -1) {
    this.updateMaskProgress(1)
    this.fsbackground.colorProgress = 1
    // }

    this.onResize()

    this.transitionIn()

    this.onRAF()

  }

  updateMaskProgress(maskProgress) {
    // viewport mask
    this.tgtMaskProgress = gsap.utils.mapRange(
      0,
      .5,
      0,
      1,
      maskProgress
    )
    this.tgtMaskProgress = gsap.utils.clamp(0, 1, this.tgtMaskProgress)

    // pack tongue
    let packProgress = gsap.utils.mapRange(
      .5,
      1,
      .5,
      1,
      this.tgtMaskProgress
    )
    packProgress = gsap.utils.clamp(0, 1, packProgress)
    this.packs.currentInstance.setTongueProgress(1 - packProgress)

    // camera control influence
    let ctrlInfluence = gsap.utils.mapRange(
      .25,
      .5,
      0,
      1,
      maskProgress
    )
    ctrlInfluence = gsap.utils.clamp(0, 1, ctrlInfluence)
    this.camCtrl.influence = ctrlInfluence

    // bg color transition
    let bgProgress = gsap.utils.mapRange(
      .5,
      1,
      0,
      1,
      maskProgress
    )
    bgProgress = gsap.utils.clamp(0, 1, bgProgress)
    this.fsbackground.tgtColorProgress = bgProgress

    // // packs: second pack comes in
    // let secondPackProgress = gsap.utils.mapRange(
    //   .65,
    //   1,
    //   0,
    //   1,
    //   maskProgress
    // )
    // // secondPackProgress = gsap.utils.clamp(0, 1, secondPackProgress)
    // this.packs.introProgress = secondPackProgress

  }

  addListeners() {
    window.addEventListener("mousedown", this.onMouseDown);
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("mouseup", this.onMouseUp);
  }

  removeListeners() {
    window.removeEventListener("mousedown", this.onMouseDown);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
  }


  transitionIn() {

    // this.camCtrl.transitionIn()

  }

  transitionOut() {
    // this.gltexts.transitionOut()

  }

  onIntroDone() {
    this.packs.showTexts()

  }

  onRAF() {
    requestAnimationFrame(this.onRAF)

    if (this.isPaused) return

    now = Date.now()
    this.dt = (now - lastTime) / 1000
    lastTime = now

    this.maskProgress += (this.tgtMaskProgress - this.maskProgress) * .1

    this.render()

    this.updateGauje()
    this.onUpdate(this.dt)
  }

  onUpdate() { }

  updateGauje() {
    const dt = this.dt
    this.gauje.update(dt)

    // opacity
    // if (this.packs.intersectBboxes.length > 0 && !this.isPlayingOpening) {
    if (this.packs.intersectBboxes.length > 0) {
      this.gauje.fade -= dt * 2
    } else if (!this.isPlayingOpening) {
      this.gauje.fade += dt * 6
    }
    this.gauje.fade = gsap.utils.clamp(0, 1, this.gauje.fade)

    // scale
    // if (this.isMouseDown) {
    //   this.gauje.progress += dt * .8
    // }
    // else {
    //   this.gauje.progress -= dt * 2
    // }
    // this.gauje.progress = gsap.utils.clamp(0, 1, this.gauje.progress)

    gaujeProps.x += (gaujeProps.tgtx - gaujeProps.x) * 0.1
    gaujeProps.y += (gaujeProps.tgty - gaujeProps.y) * 0.1
    this.view.$refs.gauje.style.transform = `translate3d(${gaujeProps.x}px, ${gaujeProps.y}px, 0)`

    this.packs.currentInstance.setTongueProgress(this.gauje.progress)
    this.camCtrl.influence = map(this.gauje.progress, 0, 1, 1, 0)
  }

  resume() {
    this.isPaused = false
    this.addListeners()
  }

  pause() {
    this.isPaused = true
    this.removeListeners()
  }

  show() {
    TweenMax.to(this.renderer.domElement, 1, { opacity: 1 })
  }

  hide() {
    TweenMax.to(this.renderer.domElement, 1, { opacity: 0, delay: 1 })
  }

  render() {
    const dt = this.dt
    this.camCtrl.update(dt)
    this.packs.update(dt)
    this.fsbackground.update(dt)

    this.updateViewport()

    this.renderer.render(this.scene, this.camera);

  }

  updateViewport() {

    const width = this.width
    const height = this.height

    const left = width * (1 - this.maskProgress)
    const bottom = 0
    this.renderer.setViewport(0, 0, width, height);
    this.renderer.setScissor(left, bottom, width, height);
    this.renderer.setScissorTest(true);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

  }

  updateMetrics(width, height) {
    this.width = width
    this.height = height
  }

  onResize() {

    const camera = this.camera
    const renderer = this.renderer

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

  }

  onMouseDown(evt) {
    this.isMouseDown = true

    downMousePos.x = evt.screenX
    downMousePos.y = evt.screenY

    TweenMax.killTweensOf([this.gauje])
    TweenMax.to(this.gauje, 0.3, { scale: 0.8, ease: Power2.easeInOut })
  }

  onMouseMove(evt) {
    gaujeProps.tgtx = evt.pageX
    gaujeProps.tgty = evt.pageY

    // if (!this.isMouseDown || this.isPlayingOpening) return
    if (!this.isMouseDown) return

    mouseTravelledDist = evt.screenX - downMousePos.x

    let progress = map(mouseTravelledDist, 0, DIST_TO_OPEN, 0, 1)
    progress = gsap.utils.clamp(0, 1, progress)
    this.gauje.progress = progress
  }

  onMouseUp() {
    this.isMouseDown = false

    TweenMax.killTweensOf([this.gauje])
    TweenMax.to(this.gauje, 0.6, { scale: 1, ease: Power2.easeInOut })

    if (this.gauje.progress < 1) {
      TweenMax.to(this.gauje, 0.6, { progress: 0 })
    } else {
      this.isPlayingOpening = true
      TweenMax.to(this.gauje, 0.6, { fade: 1 })
      this.packs.open()

      // router.push({ name: 'work', params: { slug: this.packs.currentInstance.data.slug.current } })
      this.hide()
      setTimeout(() => {
        this.onPackOpened(this.packs.currentInstance.data.slug.current)
      }, 500)

    }
  }

  onPackOpened(slug) {

  }

}