<template>
  <div class="c-loader"></div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { html } from "../../utils/environment";
import anime from "animejs";

export default {
  name: "Loader",
  computed: {
    ...mapState("main", ["pageEnteringDuration", "pageLeavingDuration"]),

    isAllLoaded() {
      return this.$store.getters["main/isAllLoaded"];
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  watch: {
    isAllLoaded: "checkAllLoaded",
  },
  created() {
    this.isLoaded = this.isAllLoaded;
    this.hasLoadOnce = false;
    this.isAnimating = false;

    if (!this.isLoaded) {
      this.setCursorState("load");
    }
  },
  methods: {
    ...mapActions("cursor", ["setCursorState"]),
    ...mapActions("main", ["setPageEntering"]),

    checkAllLoaded(isLoaded) {
      this.isLoaded = isLoaded;

      // console.log("----------");
      // console.log("isLoaded", isLoaded);

      // If it's not loaded but it's not the first time
      if (!this.isLoaded && this.hasLoadOnce) {
        this.setLoading();
      }

      // If it's loaded
      if (this.isLoaded && !this.isAnimating) {
        this.setLoaded();
      }
    },

    setLoading() {
      this.isAnimating = true;
      this.setPageEntering(false);

      // console.log("APPEAR ANIMTAION");

      anime({
        targets: this.$el,
        opacity: 1,
        duration: this.pageLeavingDuration,
        easing: "linear",
        begin: () => {
          html.classList.add("is-loading");
          this.setCursorState("load");
        },
        complete: () => {
          html.classList.remove("has-scroll-reveal");
          // console.log("APPARITION TERMINEE et LOADEE", this.isLoaded);
          if (this.isLoaded) {
            // console.log("ON ENTAME LA SUITE", this.isLoaded);
            this.setLoaded();
          } else {
            this.isAnimating = false;
          }
        },
      });
    },

    setLoaded() {
      this.hasLoadOnce = true;

      // console.log("DISAPPEAR ANIMTAION");

      anime({
        targets: this.$el,
        opacity: 0,
        duration: this.pageEnteringDuration,
        easing: "linear",
        begin: () => {
          html.classList.remove("is-loading");
          this.setCursorState("default");
        },
        complete: () => {
          this.isAnimating = false;
          this.setPageEntering(true);
          html.classList.add("is-first-loaded");
          html.classList.add("has-scroll-reveal");
        },
      });
    },
  },
};
</script>