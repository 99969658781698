

export function clamp(n, min, max) {
  if (!min) min = 0
  if (!max) max = 1
  return Math.min(max, Math.max(min, n));
}

export function sign(n) {
  return (n < 0.0) ? -1.0 : 1.0;
}

export function map(num, min1, max1, min2, max2) {
  const num1 = (num - min1) / (max1 - min1)
  let num2 = (num1 * (max2 - min2)) + min2

  return num2;
}

export function lerp(v0, v1, t) {
  return v0 * (1 - t) + v1 * t
}

export function randomFloat(minValue, maxValue) {
  return Math.min(minValue + (Math.random() * (maxValue - minValue)), maxValue)
}

export function nextPOT(n) {
  let p = 1;

  while (p < n)
    p <<= 1;

  return p;
}

export function degreesToRadians(degrees) {
  const pi = Math.PI;
  return degrees * (pi / 180);
}