import * as THREE from 'three'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import THREEShaderMaterial from '../../lib/THREEShaderMaterial';

import anime from 'animejs'

import { map } from '../../utils/math';

import store from '../../../store/main'

// const cfg = {

//   pola: {
//     main: {
//       rotation: { x: 0, y: 0, z: 0.55 }
//     },
//     rxNode: {
//       rotation: { x: 0, y: 0, z: 0 }
//     },
//     ryNode: {
//       rotation: { x: 0, y: -1, z: 0 }
//     }
//   }
// }

const NB_FRAMES = 76

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/scripts/draco/');

function sortGeoms(a, b) {
  const aa = parseInt(a.name)
  const bb = parseInt(b.name)
  if (aa < bb) {
    return -1;
  }
  if (aa > bb) {
    return 1;
  }
}


export class Pack {

  constructor(scene) {
    this.scene = scene

    this.tonguePart = null
    this.bottomPart = null

    this.node = new THREE.Object3D()
    this.rxNode = new THREE.Object3D()
    this.ryNode = new THREE.Object3D()

    this.rxNode.add(this.ryNode)
    this.node.add(this.rxNode)
    this.node.rotateX(-Math.PI / 2)

    const scale = .012
    this.node.scale.set(scale, scale, scale)
    this.node.position.y += .1

    this.children = []
    this.frames = []
    this.currentFrame = 0

    this.progress = 0
    this.tgtProgress = 0

  }

  load() {
    return new Promise(async (resolve) => {

      // load bottom part
      this.bottomPart = await this.loadScene(`models/pack/pack.glb`)

      const loader = new GLTFLoader()
      loader.setDRACOLoader(dracoLoader)

      // load sequence
      for (let i = 0; i < NB_FRAMES; i++) {
        const root = store.state.assetUrl
        const url = `models/pack/tongue_sequence/frame_${i}.glb`
        loader.load(url, (gltf) => {
          const geom = gltf.scene.children[0].children[0].geometry
          geom.name = gltf.scene.children[0].name
          this.frames.push(geom)

          if (this.frames.length == NB_FRAMES) {
            this.frames.sort(sortGeoms);
            resolve()
          }

        });

      }


    })

  }

  loadScene(url) {

    return new Promise((resolve) => {

      const loader = new GLTFLoader()

      loader.setDRACOLoader(dracoLoader)
      loader.load(url, (gltf) => {
        resolve(gltf)
      });

    })

  }

  onSceneLoaded() {

    const mats = this.scene.mats
    const texs = this.scene.texs

    // tongue
    let m = mats.get('tongue')
    console.log(m)
    const geom = this.frames[33]

    this.tonguePart = new THREE.Mesh(geom, m)
    this.ryNode.add(this.tonguePart)


    // m = mats.get('bottom')
    m = new THREEShaderMaterial({
      type: 'standard',

      vertexChunks: [
        ['start', `
          
        `],
        ['end', `

        `],
      ],
      fragmentChunks: [
        ['start', `
          uniform float uTime;
          uniform sampler2D tText;
          uniform float uTextOpacity;
        `],
        ['end', `
          vec3 textColor = texture2D(tText, vUv).rgb;
          // gl_FragColor.rgb += textColor * uTextOpacity;
          
        `],
      ],
      transparent: false
    })
    console.log(m)

    this.bottomPart.scene.traverse((child) => {
      if (child.type == 'Mesh') {
        child.material = m
        child.rotateX(Math.PI / 2)
        this.ryNode.add(child)

        const mapIds = ['map', 'roughness', 'metalness', 'normal']

        mapIds.forEach(mapId => {

          const matMapId = mapId == 'map' ? 'map' : `${mapId}Map`

          // const color = this.makeBottomTexture(mapId, {
          //   invert: cfg[mapId].invert,
          //   textColor: cfg[mapId].textColor
          // })
          // const tex = new THREE.Texture(color)
          // tex.needsUpdate = true
          m.uniforms[matMapId].value = texs.get(`bottom_${mapId}`)

        })

      }
    })
    this.node.add(this.bottomPart.scene)



    this.scene.scene.add(this.node)

    // this.transitionIn()

    this.gui()

  }

  transitionIn() {

    // anim light intensity
    const anim = {
      envIntensity: 0
    }

    for (let i = 0; i < this.children.length; i++) {
      this.children[i].material.envMapIntensity = 0
      this.children[i].material.needsUpdate = true
    }

    anime({
      targets: anim,
      envIntensity: 0.05,
      duration: 10000,
      delay: 1000,
      update: () => {
        for (let i = 0; i < this.children.length; i++) {

          this.children[i].material.envMapIntensity = anim.envIntensity
          this.children[i].material.needsUpdate = true
        }
      }
    })

  }

  update() {

    this.progress += (this.tgtProgress - this.progress) * .1
    let f = map(this.progress, 0, 1, 0, NB_FRAMES - 1)
    f = Math.floor(f)

    // this.currentFrame += dt * 20
    // const f =  Math.floor(this.currentFrame)

    // // console.log(f)

    // if (this.currentFrame > NB_FRAMES - 1) {
    //   this.currentFrame = 0
    // }

    const geom = this.frames[f]

    this.tonguePart.geometry = geom

    // this.node.rotateX(.1)
  }

  setProgress(progress) {
    this.tgtProgress = progress
  }


  gui() {
    // const fPola = gui.addFolder({title:'pola', expanded: false})

    // const fPolaMain = fPola.addFolder({title:`main node`, expanded: false})
    // fPolaMain.addInput(cfg.pola.main.rotation, 'x', {min: -Math.PI, max: Math.PI, step: .01})
    // fPolaMain.addInput(cfg.pola.main.rotation, 'y', {min: -Math.PI, max: Math.PI, step: .01})
    // fPolaMain.addInput(cfg.pola.main.rotation, 'z', {min: -Math.PI, max: Math.PI, step: .01})

    // const fPolaRx = fPola.addFolder({title:`rx node`, expanded: false})
    // fPolaRx.addInput(cfg.pola.rxNode.rotation, 'x', {min: -Math.PI, max: Math.PI, step: .01})

    // const fPolaRy = fPola.addFolder({title:`ry node`, expanded: false})
    // fPolaRy.addInput(cfg.pola.ryNode.rotation, 'y', {min: -Math.PI, max: Math.PI, step: .01})


  }

}
