

export default class Signal {
  
  constructor(){
    this._listeners = [];
  }


  on(fn){
    if( this._listeners.indexOf(fn) === -1 ){
      this._listeners.push( fn );
    }
  }


  off(fn){
    const i = this._listeners.indexOf(fn);
    if( i > -1 ){
      this._listeners.splice( i, 1 );
    }
  }


  release(){
    this._listeners = [];
  }


  emit(e){
    for (const cbk of this._listeners) {
      cbk(e);
    }
  }


}
