import { mapActions } from "vuex";

const cursorMixin = {
    computed: {
        isAllLoaded() {
            return this.$store.getters["main/isAllLoaded"];
        },
    },

    watch: {
        isAllLoaded: "onAllLoaded",
    },

    created() {
        this.onMouseEnterBind = this.onMouseEnter.bind(this)
        this.onMouseLeaveBind = this.onMouseLeave.bind(this)
    },

    beforeUnmount() {
        this.unbindCursorEvents();
    },

    methods: {
        ...mapActions('cursor', ['setCursorState']),

        bindCursorEvents() {
            const $items = document.querySelectorAll(`[data-cursor]`)
            let itemIndex = 0

            while (itemIndex < $items.length) {
                const $item = $items[itemIndex]
                $item.addEventListener('mouseenter', this.onMouseEnterBind)
                $item.addEventListener('mouseleave', this.onMouseLeaveBind)
                itemIndex++
            }
        },
        unbindCursorEvents() {
            const $items = document.querySelectorAll(`[data-cursor]`)
            let itemIndex = 0

            while (itemIndex < $items.length) {
                const $item = $items[itemIndex]
                $item.addEventListener('mouseenter', this.onMouseEnterBind)
                $item.addEventListener('mouseleave', this.onMouseLeaveBind)
                itemIndex++
            }
        },
        onMouseEnter(e) {
            const $target = e.target
            const state = $target?.dataset?.cursor
            if (state) {
                this.setCursorState(state)
            }
        },

        onMouseLeave() {
            this.setCursorState('default')
        },

        onAllLoaded(isLoaded) {
            if (isLoaded) {
                this.$nextTick(() => {
                    this.bindCursorEvents()
                })
            }
        },
    }
}

export default cursorMixin