// import { vec2 } from "gl-matrix"
import { map } from "../utils/math"
import Signal from "../lib/Signal"

import anime from 'animejs'

// const V2 = vec2.create()
// const V2A = vec2.create()
// const V2B = vec2.create()

// const offset = vec2.create()

let SIZE = 150
const LINE_WIDTH = 2

// const OVER_SCALE = 1.3


// const cfg = {
//   // gradient: 
// }

export class Gauje {

  constructor(canvas, size) {

    SIZE = size

    this.cvs = canvas
    this.events = {
      faded: new Signal()
    }

    const cvs = this.cvs
    cvs.style.width = SIZE + 'px'
    cvs.style.height = SIZE + 'px'
    cvs.width = SIZE
    cvs.height = SIZE
    // cvs.style.position = 'absolute'
    // cvs.style.left = '63.7%'
    // cvs.style.top = '65%'
    cvs.style.pointerEvents = 'none'


    this.ctx = this.cvs.getContext('2d')

    this.progress = 0
    this.fade = 1

    this.scale = 1



    this.isVisible = false

    this.hasTransitionned = false

    this.draw()

    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)

    cvs.addEventListener('mousedown', this.onMouseDown)
    cvs.addEventListener('mouseup', this.onMouseUp)
    cvs.addEventListener('click', this.onClick.bind(this))

  }

  onTouchAdded(touch) {
    this.currentTouch = touch
  }

  onMouseDown() {
    this.isMouseDown = true
  }

  onMouseUp() {
    this.isMouseDown = false
  }

  onClick() {
    // const anim = { p: 0 }

    anime({
      targets: this,
      progress: 1,
      duration: 500,
      easing: 'easeOutQuad',
      complete: () => {
        this.transitionOut()
      }
    })

  }

  update() {
    if (!this.isVisible) return

    this.draw()

  }

  draw() {

    const ctx = this.ctx

    ctx.lineWidth = LINE_WIDTH

    ctx.clearRect(0, 0, SIZE, SIZE)

    // const size = SIZE * this.scale
    const borderScale = map(this.fade, 0, 1, .7, 1)
    const borderSize = (SIZE / 2 - LINE_WIDTH) * borderScale

    ctx.globalAlpha = 1 - this.fade

    // border
    ctx.save()
    ctx.translate(SIZE / 2, SIZE / 2)
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.3)'
    ctx.beginPath()
    ctx.arc(0, 0, borderSize * this.scale, 0, Math.PI * 2)
    ctx.closePath()
    ctx.stroke()
    ctx.restore()

    // middle dot
    const middleDotRadius = map(this.scale, 1, 1.3, 4, 2.5)
    ctx.save()
    ctx.translate(SIZE / 2, SIZE / 2)
    ctx.fillStyle = 'white'
    ctx.beginPath()
    ctx.arc(0, 0, middleDotRadius, 0, Math.PI * 2)
    ctx.closePath()
    ctx.fill()
    ctx.restore()

    // fill
    ctx.save()
    ctx.translate(SIZE / 2, SIZE / 2)
    ctx.rotate(-Math.PI * .5)
    ctx.strokeStyle = 'rgba(34, 66, 232, .7)'
    ctx.beginPath()
    ctx.arc(0, 0, borderSize * this.scale, 0, Math.PI * Math.pow(this.progress, 2) * 2)
    // ctx.closePath()
    ctx.stroke()
    ctx.restore()

  }

  transitionIn() {
    if (this.isVisible) return
    this.isVisible = true

    this.cvs.style.pointerEvents = 'auto'
    this.cvs.style.display = 'block'
    setTimeout(() => {

      anime({
        targets: this,
        fade: 0,
        duration: 2000,
        delay: 0,
        easing: 'easeInOutQuint'
      })

    }, 100)

  }

  transitionOut() {
    this.cvs.style.pointerEvents = 'none'
    anime({
      targets: this,
      fade: 1,
      duration: 1000,
      easing: 'easeOutQuart',
      complete: () => {
        this.isVisible = false
      }
    })
    this.events.faded.emit()
  }

}