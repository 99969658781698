import * as THREE from 'three'
import { map } from "../../utils/math"
import gui from "../../dev/gui"
import THREEShaderMaterial from "../../lib/THREEShaderMaterial"
import { TweenMax, Power4 } from 'gsap'
// import { ShaderMaterial } from "three"


const NB_FRAMES = 76

const MAP_SIZE = 1024

const cfg = {
  color: '#5f5f5f',
  envmapIntensity: .13,
  roughness: 0.56,
  metalness: 0.98,
  map: {
    ocvs: null,
    octx: null,
    cvs: null,
    ctx: null,
    invert: 0,
    textColor: '#ffffff'
  },
  roughnessMap: {
    ocvs: null,
    octx: null,
    cvs: null,
    ctx: null,
    invert: 0,
    textColor: '#ffffff'
  },
  metalnessMap: {
    ocvs: null,
    octx: null,
    cvs: null,
    ctx: null,
    invert: 0,
    textColor: '#ffffff'
  },
}

let updateConfigTimer = null

let guiInit = false

let V3 = new THREE.Vector3()

const tmpColor = new THREE.Color(cfg.color)


export class PackInstance {

  /**
   * 
   * @param {Scene} scene 
   * @param {GLTF} template 
   * @param {Object} data date, title, id
   */
  constructor(scene, template, data) {
    console.log(data)
    this.scene = scene
    this.template = template

    this.data = data

    this.tgtTongueProgress = 0
    this.tongueProgress = 0

    this.node = new THREE.Object3D()
    this.rxNode = new THREE.Object3D()
    this.ryNode = new THREE.Object3D()
    this.bottomNode = new THREE.Object3D()

    this.polaroid = null

    this.ryNode.add(this.bottomNode)
    this.rxNode.add(this.ryNode)
    this.node.add(this.rxNode)

    this.frames = []
    this.tonguePart = null
    this.bottomPart = null

    this.textOpacity = 0.1

    this.restPosX = 0
    this.idx = 0

    this.applyMatConfig = this.applyMatConfig.bind(this)

    this.markers = []


    // make bottom 
    this.makeTongue()
    this.makeBottom()
    this.makeBottomTexture()
    this.makeBBox()
    this.makePolaroid()

    // this.makeMarkers()

    gui.isDev && this.gui()
  }

  makeBBox() {

    const geometry = new THREE.PlaneBufferGeometry(280, 280);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00, depthTest: false, transparent: true, opacity: 0.1 });
    const bbox = new THREE.Mesh(geometry, material);
    bbox.position.y = -15
    this.bbox = bbox
    this.bbox.visible = false

    this.ryNode.add(this.bbox)

  }

  makePolaroid() {


    const geometry = new THREE.PlaneBufferGeometry(116.6 * 2, 77.3 * 2);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const polaroid = new THREE.Mesh(geometry, material);
    polaroid.position.y = -15
    polaroid.position.z = -10
    this.polaroid = polaroid

    // this.ryNode.add(this.polaroid)

  }

  makeMarkers() {

    const geometry = new THREE.PlaneBufferGeometry(4, 4);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00, depthTest: false });
    const lMarker = new THREE.Mesh(geometry, material);
    const rMarker = new THREE.Mesh(geometry, material);
    lMarker.position.set(-135, 92, -10)
    rMarker.position.set(135, 92, -10)
    // lMarker.position.z += 1

    this.node.add(lMarker)
    this.node.add(rMarker)

    this.markers.push(lMarker)
    this.markers.push(rMarker)

    lMarker.screenPos = new THREE.Vector2()
    rMarker.screenPos = new THREE.Vector2()

  }

  makeTongue() {

    // copy frames from template
    for (let i = 0; i < this.template.frames.length; i++) {
      this.frames.push(this.template.frames[i].clone())
    }

    const mats = this.scene.mats

    let m = mats.get('tongue').clone()

    const geom = this.frames[0]

    this.tonguePart = new THREE.Mesh(geom, m)
    this.tonguePart.rotateX(-Math.PI / 2)
    this.ryNode.add(this.tonguePart)
  }

  makeBottom() {
    const scene = this.scene
    // const mats = scene.mats
    const texs = scene.texs

    this.bottomPart = this.template.bottomPart

    // const m = mats.get('bottom').clone()

    let m = new THREEShaderMaterial({
      type: 'standard',

      vertexChunks: [
        ['start', `
          
        `],
        ['end', `

        `],
      ],
      fragmentChunks: [
        ['start', `
          uniform float uTime;
          uniform sampler2D tText;
          uniform float uTextOpacity;
        `],
        ['end', `
          vec3 textColor = texture2D(tText, vUv).rgb;
          gl_FragColor.rgb += textColor * uTextOpacity;
          gl_FragColor.a = 1.;
          
        `],
      ],
      transparent: false,
      defines: {
        'STANDARD': ""
      }
    })


    this.bottomPart.scene.traverse((child) => {
      if (child.type == 'Mesh') {
        const geom = child.geometry.clone()

        // const mapIds = ['map', 'roughness', 'metalness', 'normal']
        const mapIds = ['map']

        mapIds.forEach(mapId => {

          const matMapId = mapId == 'map' ? 'map' : `${mapId}Map`

          m.uniforms[matMapId].value = texs.get(`bottom_${mapId}`)

        })

        // m.color.setStyle(cfg.color)
        m.uniforms.diffuse.value = tmpColor
        m.uniforms.roughness.value = cfg.roughness
        m.uniforms.metalness.value = cfg.metalness
        m.uniforms.envMapIntensity.value = cfg.envmapIntensity
        m.uniforms.envMap.value = this.scene.envMap

        const tText = new THREE.Texture(this.makeBottomTexture())
        tText.needsUpdate = true
        m.uniforms.tText.value = tText

        const mesh = new THREE.Mesh(geom, m)
        this.bottomNode.add(mesh)
      }
    })

    this.bottomMat = m
  }

  makeBottomTexture() {

    const textColor = '#fff7f7'
    const offsetCvs = document.createElement('canvas')
    const offsetCtx = offsetCvs.getContext('2d')

    offsetCvs.style.width = offsetCvs.style.height = `${MAP_SIZE}px`
    offsetCvs.width = offsetCvs.height = MAP_SIZE


    // draw title
    let fontSize = 30
    let text = this.data.title//'LIFE,UNDERGROUND,PROJECT'
    const lineHeight = fontSize + 5
    offsetCtx.save()
    offsetCtx.font = `${fontSize}px Arial`;
    offsetCtx.fillStyle = textColor
    offsetCtx.translate(920 - fontSize / 2, 250)
    offsetCtx.rotate(Math.PI * .5)

    const lines = text.split(',')
    for (var i = 0; i < lines.length; i++) {
      offsetCtx.fillText(lines[i], 0, i * lineHeight);
    }

    offsetCtx.restore()

    // draw id
    fontSize = 300
    offsetCtx.font = `${fontSize}px Austin-Italic-Web`
    offsetCtx.save()
    offsetCtx.fillStyle = textColor
    offsetCtx.translate(370, 900)
    offsetCtx.rotate(-Math.PI * .5)
    offsetCtx.fillText('F', 0, 0);
    offsetCtx.restore()

    offsetCtx.font = `${fontSize}px ABCDiatype-Bold`
    offsetCtx.save()
    offsetCtx.fillStyle = textColor
    offsetCtx.translate(370, 800)
    offsetCtx.rotate(-Math.PI * .5)
    offsetCtx.fillText(` -0${this.data.index}`, 0, 0);
    offsetCtx.restore()

    // final canvas to rotate for webgl
    const cvs = document.createElement('canvas')
    const ctx = cvs.getContext('2d')

    cvs.style.width = cvs.style.height = `${MAP_SIZE}px`
    cvs.width = cvs.height = MAP_SIZE

    ctx.translate(0, 1024)
    ctx.rotate(Math.PI)
    ctx.scale(-1, 1)
    ctx.drawImage(offsetCvs, 0, 0)

    // if (cfg[mapId].cvs == null) {
    //   console.log(cfg[mapId])
    //   cfg[mapId].ocvs = offsetCvs
    //   // cfg[mapId].octx = offsetCtx
    //   cfg[mapId].cvs = cvs
    //   // cfg[mapId].ctx = ctx
    //   console.log('put in cache', mapId)
    // }

    // cvs.style.position = 'absolute'; cvs.style.left = cvs.style.top = 0; cvs.style.zIndex = 9999
    // cvs.style.border = '1px solid red'
    // document.body.appendChild(cvs)

    return cvs

  }

  update() {

    this.tongueProgress += (this.tgtTongueProgress - this.tongueProgress) * 0.1
    let f = map(this.tongueProgress, 0, 1, 0, NB_FRAMES - 1)
    f = Math.floor(f)

    const geom = this.frames[f]

    this.tonguePart.geometry = geom

    // this.updateMarkersScreenPos()

    this.bottomMat.uniforms.uTextOpacity.value = this.textOpacity * this.scene.packs.globalTextOpacity

  }

  updateMarkersScreenPos() {
    const renderer = this.scene.renderer
    const camera = this.scene.camera

    var widthHalf = 0.5 * renderer.getContext().canvas.width;
    var heightHalf = 0.5 * renderer.getContext().canvas.height;

    for (let i = 0; i < 2; i++) {
      const marker = this.markers[i]

      marker.updateMatrixWorld();
      V3.setFromMatrixPosition(marker.matrixWorld);
      V3.project(camera);

      V3.x = (V3.x * widthHalf) + widthHalf;
      V3.y = - (V3.y * heightHalf) + heightHalf;

      marker.screenPos.x = Math.floor(V3.x)
      marker.screenPos.y = Math.floor(V3.y)
    }

  }

  applyMatConfig() {

    clearTimeout(updateConfigTimer)

    updateConfigTimer = setTimeout(() => {
      this.makeBottom()
    }, 1000)

  }

  transitionIn() {
    TweenMax.to(this, 1, { textOpacity: .9 })

    this.bbox.position.x = 0
  }

  transitionOut() {
    TweenMax.to(this, 1, { textOpacity: 0.1 })

    this.bbox.position.x = -10000
  }

  setTongueProgress(progress) {
    this.tgtTongueProgress = progress
  }

  open() {

    const tongueProps = { rx: 0, opacity: 1 }
    const bottomProps = { y: 0, rz: 0 }
    const polaroidPops = { scale: 1, y: -13 }

    // TweenMax.to( this.tonguePart.material, 1, {opacity: 0} )

    TweenMax.to(tongueProps, 1, {
      rx: -.03, opacity: 0, onUpdate: () => {
        this.tonguePart.rotateX(tongueProps.rx)
        this.tonguePart.material.opacity = tongueProps.opacity
      }
    })

    TweenMax.to(bottomProps, 2, {
      y: -450, rz: -.05, ease: Power4.easeIn, onUpdate: () => {
        this.bottomNode.rotateZ(bottomProps.rz)
        this.bottomNode.position.y = bottomProps.y
      }
    })

    TweenMax.to(polaroidPops, 1.5, {
      scale: 2.05, y: -50, delay: 3, ease: Power4.easeInOut, onUpdate: () => {
        this.polaroid.scale.setScalar(polaroidPops.scale)
        this.polaroid.position.y = polaroidPops.y
      }
    })

  }

  makeSomeSpace(direction) {
    console.log(direction)
    const props = { x: this.node.position.x }

    TweenMax.to(props, 1.5, {
      x: this.node.position.x + 300 * direction, ease: Power4.easeInOut, onUpdate: () => {
        this.node.position.x = props.x
      }
    })

  }

  gui() {
    if (guiInit) return

    guiInit = true

    const f = gui.addFolder({ title: 'pack', expanded: false })
    f.addInput(cfg, 'color').on('change', this.applyMatConfig)
    f.addInput(cfg, 'roughness', { min: 0, max: 1, step: 0.01 }).on('change', this.applyMatConfig)
    f.addInput(cfg, 'metalness', { min: 0, max: 1, step: 0.01 }).on('change', this.applyMatConfig)
    f.addInput(cfg, 'envmapIntensity', { min: 0, max: 1, step: 0.01 }).on('change', this.applyMatConfig)

    const fMap = f.addFolder({ title: `map`, expanded: false })
    const fRougnessMap = f.addFolder({ title: `roughnessMap`, expanded: false }).on('change', this.applyMatConfig)
    const fMetalnessMap = f.addFolder({ title: `metalnessMap`, expanded: false }).on('change', this.applyMatConfig)

    fMap.addInput(cfg.map, 'invert', { min: 0, max: 1, step: 0.01 }).on('change', this.applyMatConfig)
    fMap.addInput(cfg.map, 'textColor').on('change', this.applyMatConfig)
    fRougnessMap.addInput(cfg.map, 'invert', { min: 0, max: 1, step: 0.01 }).on('change', this.applyMatConfig)
    fRougnessMap.addInput(cfg.map, 'textColor').on('change', this.applyMatConfig)
    fMetalnessMap.addInput(cfg.map, 'invert', { min: 0, max: 1, step: 0.01 }).on('change', this.applyMatConfig)
    fMetalnessMap.addInput(cfg.map, 'textColor').on('change', this.applyMatConfig)


  }
}