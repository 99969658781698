import * as THREE from 'three'
import { Power2, Power4, TweenMax } from "gsap/all";
import { Pack } from ".";
import { lerp } from "../../utils/math";
import { PackInstance } from "./pack-instance";

// const mouse = {x: 0, y: 0, isDown: false}
const downMouse = { x: 0, y: 0 }

import metrics from '../../../store/metrics'

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

export class Packs {

  constructor(scene) {
    this.scene = scene
    this.templatePack = new Pack(scene)

    this.node = new THREE.Object3D()
    const scale = .013
    this.node.scale.set(scale, scale, scale)

    this.node.position.y = .4

    scene.scene.add(this.node)

    this.instances = []
    this.bboxes = []
    this.currentInstanceIdx = 0
    this.currentInstance = null

    this.globalTextOpacity = 0

    this.canTranslate = true

    this.intersectBboxes = []

    this.onWheel = this.onWheel.bind(this)

    window.addEventListener('mousemove', this.onMouseMove.bind(this))
    window.addEventListener('mousedown', this.onMouseDown.bind(this))
    window.addEventListener('mouseup', this.onMouseUp.bind(this))

  }

  addWheelListener() {
    window.addEventListener('wheel', this.onWheel)
  }

  removeWheelListener() {
    window.removeEventListener('wheel', this.onWheel)
  }

  onWheel(evt) {
    if (!this.canTranslate) return

    // disable scroll
    this.canTranslate = false

    // reset current pack
    this.resetCurrentPackOrientation()
    this.currentInstance.transitionOut()

    const dir = evt.deltaY < 0 ? -1 : 1

    this.currentInstanceIdx += dir
    this.currentInstanceIdx = Math.min(Math.max(0, this.currentInstanceIdx), this.instances.length - 1)
    this.currentInstance = this.instances[this.currentInstanceIdx]

    // update cam controller what is the current instance
    this.scene.camCtrl.setObject(this.instances[this.currentInstanceIdx].node)

    // translate cam
    const nextPosX = this.instances[this.currentInstanceIdx].node.position.x * this.node.scale.x
    TweenMax.to(this.scene.camera.position, 1.5, {
      'x': nextPosX, ease: Power2.easeInOut, onComplete: () => {
        this.canTranslate = true
      }
    })

    this.currentInstance.transitionIn()
  }

  onMouseMove(evt) {
    // mouse.x = evt.pageX
    // mouse.y = evt.pageY

    // if (mouse.isDown) {
    //   const dx = mouse.x - downMouse.x
    //   const maxDist = 500
    //   let tongueProgress = map(dx, 0, maxDist, 0, .8)
    //   tongueProgress = Math.min(Math.max(0, tongueProgress), .8)
    //   this.currentInstance.tgtTongueProgress = tongueProgress
    // }
    mouse.x = (evt.pageX / metrics.state.width) * 2 - 1;
    mouse.y = - (evt.pageY / metrics.state.height) * 2 + 1;
  }

  onMouseDown() {
    mouse.isDown = true

    downMouse.x = mouse.x
    downMouse.y = mouse.y

  }

  onMouseUp() {
    mouse.isDown = false

    // this.currentInstance.tgtTongueProgress = 0
  }

  load() {
    return new Promise((resolve) => {
      this.templatePack.load()
        .then(() => {
          resolve()
        })
    })
  }

  onSceneLoaded() {
    this.makePacks()
  }

  resetCurrentPackOrientation() {
    const duration = 1
    TweenMax.to(this.currentInstance.rxNode.rotation, duration, { 'x': 0 })
    TweenMax.to(this.currentInstance.rxNode.rotation, duration, { 'x': 0 })
  }

  makePacks() {
    const margin = 50

    for (let i = 0; i < this.scene.data.length; i++) {
      const d = this.scene.data[i]
      const instance = new PackInstance(this.scene, this.templatePack, d)

      const x = (i * 300) + (margin * i)
      instance.node.position.x = x
      instance.idx = i
      instance.restPosX = x

      this.node.add(instance.node)
      this.instances.push(instance)
      this.bboxes.push(instance.bbox)
    }

    this.currentInstance = this.instances[this.currentInstanceIdx]

    this.instances[1].node.position.x += 300

  }

  showTexts() {

    TweenMax.to(this, 2, { globalTextOpacity: 1, ease: Power4.easeOut, delay: 2 })
    TweenMax.to(this, 3, {
      introProgress: 1, ease: Power4.easeOut, onComplete: () => {
        this.addWheelListener()
      }
    })
  }

  open() {

    for (let i = 0; i < this.instances.length; i++) {
      const instance = this.instances[i]
      if (i == this.currentInstanceIdx) {
        instance.open()
      }
      else {
        // const direction = i - this.currentInstanceIdx > 0 ? 1 : -1
        // instance.makeSomeSpace(direction)
      }
    }

  }

  update(dt) {
    for (let i = 0; i < this.instances.length; i++) {
      this.instances[i].update(dt)
    }

    this.updateIntroTransition()
    this.raycast()

  }

  updateIntroTransition() {

    // intro progress : second pack position
    const pos1 = this.instances[1].restPosX
    const pos2 = this.instances[1].restPosX + 300
    let posx = lerp(pos2, pos1, this.introProgress)
    this.instances[1].node.position.x = posx
    // console.log( this.currentInstance.markers[0].screenPos )

  }


  raycast() {

    // update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, this.scene.camera);

    // calculate objects intersecting the picking ray
    this.intersectBboxes = raycaster.intersectObjects(this.bboxes);

  }

}