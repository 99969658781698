const actions = {
    setWheelIncrement: (context, val) => {
        context.commit('INCREMENT_WHEEL', val)
    },
    setCurrentScroll: (context, val) => {
        context.commit('CURRENT_SCROLL', val)
    },
    setSmoothState: (context, val) => {
        context.commit('IS_SMOOTH', val)
    },
    setLimitScroll: (context, val) => {
        context.commit('LIMIT_SCROLL', val)
    }
};

export default actions;