<template>
    <div class="c-wheel">
        <div class="c-wheel_inner" ref="rotateEl">
            <svg
                class="svg-wheel"
                role="img"
                aria-hidden="true"
                focusable="false"
            >
                <use xlink:href="@/assets/svg/wheel.svg"></use>
            </svg>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

const LERP = 0.1;

export default {
    name: "Wheel",
    computed: {
        ...mapState("scroll", ["incrementWheel"]),
    },
    watch: {
        incrementWheel: "onIncrementWheel",
    },
    methods: {
        onIncrementWheel(scrollY) {
            this.rotateWheel(scrollY);
        },

        rotateWheel(scrollY) {
            const rawRotateValue = (scrollY * LERP) % 360;
            this.$refs.rotateEl.style.transform = `rotate3d(0, 0, 1, ${rawRotateValue}deg)`;
        },
    },
};
</script>