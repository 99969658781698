<template>
  <div class="c-page -home">
    <div class="gl-container" ref="glContainer"></div>
    <canvas class="gauje" ref="gauje"></canvas>
    <div
      class="o-container || o-padding -padding-large-top -padding-large-bottom"
      :class="{ mobile: isMobile }"
      v-if="isMobile"
    >
      <ul>
        <li
          class="o-padding -padding-small-top"
          v-for="(work, index) in works"
          :key="index"
        >
          <router-link
            :to="{ name: 'Work', params: { slug: work.slug.current } }"
          >
            <p>
              <span><i>F</i></span>
              <span>-{{ uPad(work.index, 10) }}</span>
            </p>
            <p style="font-weight: bold">{{ work.title }}</p>
            <img :src="covers[work.slug.current]" alt="tmp" />

            <!-- 
          <p>
            <router-link
              class="u-color-tonic"
              :to="{ name: 'Work', params: { slug: work.slug.current } }"
              >Link {{ work.title }}</router-link
            >
          </p>

          <p>Is active ? {{ work.isActive }}</p>

          <p>{{ work.packLabel }}</p> -->
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import sanity from "../client";
import { mapActions, mapState } from "vuex";
import { HOME_QUERY } from "../core/queries/homeQuery";

import cursorMixin from "../mixins/cursorMixin";

import { ScenePacks } from "../webgl/scene_works";

import { WORK_QUERY } from "../core/queries/workQuery";
import { getContextImageUrl } from "../core/images/contextImage";

import isMobile from "../utils/isMobile";

export default {
  name: "Home",
  mixins: [cursorMixin],
  data() {
    return {
      isMobile: isMobile().phone || isMobile().tablet,
      works: {},
      covers: {},
    };
  },

  computed: {
    ...mapState("main", ["isPageEntering", "pageLeavingDuration"]),
    ...mapState("metrics", ["width", "height"]),
    ...mapState("cursor", ["cursorCoords"]),
  },

  watch: {
    width: "onResize",
    cursorCoords: "onCursorCoordsChange",
    isPageEntering: "onPageEntering",
  },

  beforeRouteLeave(to, from, next) {
    this.onPageLeaving(next);
  },

  created() {
    this.lastCamX = 0;
    this.deltaCamX = 0;
    // No images to fetch
    this.setImagesLoaded(true);

    // Fetch data
    this.fetchData();
  },

  methods: {
    ...mapActions("main", ["setDataLoaded", "setImagesLoaded"]),
    ...mapActions("scroll", ["setWheelIncrement"]),

    async fetchData() {
      const params = {};

      // Fetch data
      await sanity.fetch(HOME_QUERY, params).then(
        async (works) => {
          const sortedWorks = works.sort((a, b) =>
            a.index > b.index ? 1 : -1
          );
          this.works = sortedWorks.filter((w) => w.isActive);

          for (let i = 0; i < this.works.length; i++) {
            const slug = this.works[i].slug.current;
            await this.fetchProject(slug);
          }

          this.$nextTick(() => {
            this.setDataLoaded(true);
            this.onDataLoaded();
          });
        },
        (error) => {
          console.error(error);
        }
      );
    },

    fetchProject(slug) {
      return new Promise((resolve) => {
        const params = { slug };

        // Fetch data
        sanity.fetch(WORK_QUERY, params).then(
          (works) => {
            console.log(works);
            const work = works[0];
            const cover = work.cover.asset._ref;
            const imageSrc = getContextImageUrl(cover, "cover");
            this.covers[slug] = imageSrc;
            resolve();
          },
          (error) => {
            console.error(error);
          }
        );
      });
    },

    uPad(number, base) {
      return number < base ? "0" + number.toString() : number.toString();
    },

    getReleasedYear(releasedAt) {
      const releasedDate = new Date(releasedAt);
      return releasedDate.getFullYear();
    },

    onCursorCoordsChange() {
      // TODO: TO REMOVE ?
      //console.log("COORDS", coords)
    },

    onPackOpened(slug) {
      setTimeout(() => {
        this.$router.push({ name: "Work", params: { slug } });
      }, 1500);
    },

    onUpdate() {
      const camx = this.scene.camera.position.x;
      this.deltaCamX = this.lastCamX - camx;
      this.setWheelIncrement(this.deltaCamX * 100);
      this.lastCamX = camx;
    },

    onDataLoaded() {
      console.log("hello");
      if (this.isMobile) return;
      this.scene = new ScenePacks(this);
      this.scene
        .load()
        .then(this.onWebGLSceneLoaded.bind(this))
        .catch((err) => console.log(err));

      this.scene.onPackOpened = this.onPackOpened.bind(this);
      this.scene.onUpdate = this.onUpdate.bind(this);

      this.cvs = this.scene.renderer.domElement;
      this.$refs.glContainer.appendChild(this.cvs);
    },

    onWebGLSceneLoaded() {
      console.log("pack scene loaded");
      setTimeout(() => {
        this.scene.resume();
        this.scene.onIntroDone();
      }, 1000);
    },

    onResize() {
      this.scene?.updateMetrics?.(this.width, this.height);
    },

    onPageEntering(isEntering) {
      if (!isEntering) return;
      console.log("ENTERING ANIMATION HERE");
    },

    onPageLeaving(next) {
      this.setImagesLoaded(false);
      this.setDataLoaded(false);
      this.scene?.pause();
      // After animation complete
      setTimeout(() => {
        next();
      }, this.pageLeavingDuration);
    },
  },
};
</script>

<style lang="scss" scoped>
.o-container {
  margin-bottom: 5rem;

  &:not(.mobile) {
    margin-top: -100vh;
    position: relative;
  }

  p {
    line-height: 16px;
  }

  img {
    display: block;
    margin: 0.5rem 0 1rem 0;
  }
}

.gl-container {
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
}

.gauje {
  // width: 80px;
  // height: 80px;
  // border: 1px solid white;
  // border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  margin: -40px 0 0 -40px;
  // transform-origin: center center;
}
</style>