const actions = {
    setFontsLoaded: (context, val) => {
        context.commit('SET_FONTS_LOADED', val);
    },
    setDataLoaded: (context, val) => {
        context.commit('SET_DATA_LOADED', val);
    },
    setImagesLoaded: (context, val) => {
        context.commit('SET_IMAGES_LOADED', val);
    },
    setActiveVimeoId: (context, val) => {
        context.commit('SET_ACTIVE_VIMEO_ID', val);
    },
    forceScrollUpdate: (context, val) => {
        context.commit('FORCE_SCROLL_UPDATE', val);
    },
    setPageEntering: (context, val) => {
        context.commit('SET_PAGE_ENTERING', val);
    }
};

export default actions;
