const mutations = {
    INCREMENT_WHEEL: (state, val) => {
        state.incrementWheel += val;
    },
    CURRENT_SCROLL: (state, val) => {
        state.currentScroll = val;
    },
    IS_SMOOTH: (state, val) => {
        state.isSmooth = val;
    },
    LIMIT_SCROLL: (state, val) => {
        state.limitScroll = val
    }
};

export default mutations;
