<template>
    <div class="c-grid-helper" :class="{ 'is-active': isActive }">
        <div class="c-grid-helper_inner">
            <div class="c-grid-helper_full || o-container">
                <div
                    class="c-grid-helper_full || o-grid -gutter-x"
                    :class="[colsClass]"
                >
                    <span v-for="(col, index) in cols" :key="index"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GridHelper",
    props: {
        cols: {
            type: Number,
            default: 12,
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
    computed: {
        colsClass() {
            return `-col-${this.cols}`;
        },
    },
    created() {
        this.onKeyupBind = (e) => {
            if (e.key === "g") this.toggle();
        };
    },
    mounted() {
        this.bindEvents();
    },
    beforeUnmount() {
        this.unbindEvents();
    },
    methods: {
        ///////////////
        // Events
        ///////////////
        bindEvents() {
            document.addEventListener("keyup", this.onKeyupBind);
        },

        unbindEvents() {
            document.removeEventListener("keyup", this.onKeyupBind);
        },

        ///////////////
        // Methods
        ///////////////
        toggle() {
            this.isActive = !this.isActive;
        },
    },
};
</script>