import { createStore } from "vuex" 
import main from "./main/index"
import metrics from "./metrics/index"
import scroll from "./scroll/index"
import cursor from "./cursor/index"

const store = createStore({
    namespaced: true,
    modules: {
        main,
        metrics,
        scroll,
        cursor
    }
});

export default store;
