import * as THREE from 'three'
import gui from "./dev/gui"

const cfg = {
  light1: {
    color: '#fff',
    position: { x: -5, y: 5, z: 0 },
    intensity: .05,
    width: 10,
    height: 10
  },
  light2: {
    color: '#fff',
    position: { x: 5, y: 0, z: 0 },
    intensity: 0.7,
    width: 10,
    height: 10
  },
  light3: {
    color: '#fff',
    position: { x: 0, y: 0, z: -5 },
    intensity: 0,
    width: 10,
    height: 10
  }
}

class Lights {

  constructor(scene) {

    this.scene = scene.scene

    this.light1 = new THREE.RectAreaLight(cfg.light1.color, cfg.light1.intensity, cfg.light1.width, cfg.light1.height);
    this.light1.position.set(cfg.light1.position.x, cfg.light1.position.y, cfg.light1.position.z);
    this.light1.lookAt(0, 0, 0);
    scene.add(this.light1)

    this.light2 = new THREE.RectAreaLight(cfg.light2.color, cfg.light2.intensity, cfg.light2.width, cfg.light2.height);
    this.light2.position.set(cfg.light2.position.x, cfg.light2.position.y, cfg.light2.position.z);
    this.light2.lookAt(0, 0, 0);
    scene.add(this.light2)

    this.light3 = new THREE.RectAreaLight(cfg.light3.color, cfg.light3.intensity, cfg.light3.width, cfg.light3.height);
    this.light3.position.set(cfg.light3.position.x, cfg.light3.position.y, cfg.light3.position.z);
    this.light3.lookAt(0, 0, 0);
    scene.add(this.light3)

    // const ambient = new THREE.AmbientLight( 0xffffff );
    // scene.add( ambient );

    gui.isDev && this.gui()

  }

  gui() {

    this.updateLights = this.updateLights.bind(this)

    const f = gui.addFolder({ title: 'lights', expanded: false })

    for (let i = 1; i <= 3; i++) {
      // light 1
      const lf = f.addFolder({ title: `light${i}`, expanded: false })
      lf.addInput(cfg[`light${i}`], 'color', { input: 'color' }).on('change', this.updateLights)
      lf.addInput(cfg[`light${i}`].position, 'x', { min: -10, max: 10, step: .1 }).on('change', this.updateLights)
      lf.addInput(cfg[`light${i}`].position, 'y', { min: -10, max: 10, step: .1 }).on('change', this.updateLights)
      lf.addInput(cfg[`light${i}`].position, 'z', { min: -10, max: 10, step: .1 }).on('change', this.updateLights)
      lf.addInput(cfg[`light${i}`], 'intensity', { min: 0, max: 3, step: .1 }).on('change', this.updateLights)
      lf.addInput(cfg[`light${i}`], 'width', { min: 0.1, max: 10, step: .1 }).on('change', this.updateLights)
      lf.addInput(cfg[`light${i}`], 'height', { min: 0.1, max: 10, step: .1 }).on('change', this.updateLights)
    }

  }

  updateLights() {
    for (let i = 1; i <= 3; i++) {
      const lcfg = cfg[`light${i}`]
      this[`light${i}`].position.set(lcfg.position.x, lcfg.position.y, lcfg.position.z);
      this[`light${i}`].lookAt(0, 0, 0);
      this[`light${i}`].color.set(lcfg.color)
      console.log(lcfg.color)
      this[`light${i}`].intensity = lcfg.intensity
      this[`light${i}`].width = lcfg.width
      this[`light${i}`].height = lcfg.height
    }
  }

}

export default Lights