const actions = {
  setWidth: (context, val) => {
      context.commit('SET_WIDTH', val);
  },
  setHeight: (context, val) => {
      context.commit('SET_HEIGHT', val)
  },
  setDevicePixelRatio: (context, val) => {
      context.commit('SET_DEVICE_PIXEL_RATIO', val)
  }
};

export default actions;
