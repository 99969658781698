export const WORK_QUERY = `*[_type == "project" && slug.current == $slug]{ 
    components,
    "cover": defaultImage,
    credentials,
    index,
    introduction,
    informations,
    legals,
    releasedAt,
    slug,
    title
}[0...1]`;

export const NEXT_WORK_QUERY = `*[_type == "project" && slug.current != $slug && isActive == true]{
    "cover": defaultImage,
    index,
    isActive,
    slug,
    title
}[0...50]`;
