import imageUrlBuilder from "@sanity/image-url";
import sanity from "../../client";
import { loadImage } from "../../utils/image";
import isMobile from "../../utils/isMobile";
import { IMAGES_TYPES } from "./imagesTypes";

// Set image builder
const imageBuilder = imageUrlBuilder(sanity);

// Get is mobile state
const isMobileObj = isMobile()
const IS_TOUCH_DEVICE = isMobileObj?.phone || isMobileObj?.tablet

// Set Max image width
const MAX_IMAGE_WIDTH = 2880
const MAX_IMAGE_WIDTH_MOBILE = 1000

// Set Media MQ 
const MOBILE_MQ = 769

export function getContextImageUrl(imageObj, imageType) {
    // Prepare raw width
    let rawWidth;
    let maxWidth;

    // Get image type
    const targetImageType = IMAGES_TYPES[imageType]
    if (!targetImageType) return ''

    // Get raw width
    if (IS_TOUCH_DEVICE && window.innerWidth < MOBILE_MQ) {
        rawWidth = targetImageType.mobile
        maxWidth = MAX_IMAGE_WIDTH_MOBILE
    } else {
        rawWidth = targetImageType.desktop
        maxWidth = MAX_IMAGE_WIDTH
    }

    // Get context width
    const contextWidth = getImageWidthByUserContext(rawWidth, maxWidth)

    // Then return image url (cdn)
    const imageUrl = imageUrlFor(imageObj).width(contextWidth).url()
    //console.log(imageUrl, window.devicePixelRatio)
    return imageUrl
}

export function imageUrlFor(source) {
    return imageBuilder.image(source);
}

export function preloadImages($imagesToLoad, callback) {
    if (!$imagesToLoad.length) {
        callback?.();
        return;
    }

    const promises = [];

    $imagesToLoad.forEach((imageUrl) => {
        const promise = loadImage(imageUrl);
        promises.push(promise);
    });

    Promise.all(promises).then(() => {
        callback?.();
    });
}

// https://gist.github.com/Danetag/ccef6a07d160f4a7ad7ab3146fd23537#file-getimagewidthbyusercontext-js
export const getImageWidthByUserContext = (width, MAX_WIDTH = MAX_IMAGE_WIDTH) => {
    if (typeof (window) === 'undefined') return width;

    // Pixel ratio
    let qualityRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;

    // If bad connection, we downgrade
    let type = null;
    if (navigator && navigator.connection && navigator.connection.type) type = navigator.connection.type;
    if (navigator && navigator.connection && navigator.connection.effectiveType) type = navigator.connection.effectiveType;

    if (type !== null && (type !== 'wifi' && type !== '4g')) qualityRatio = 1;

    width *= qualityRatio;

    if (width > MAX_WIDTH) width = MAX_WIDTH;

    return Math.round(width);
}