import * as THREE from 'three'

const vs = `
void main() {
  gl_Position = vec4(position, 1.0);
}
`

const fs = `
uniform float uColorProgress;

void main() {
  vec3 color1 = vec3(0.796078431, 0.729411765, 0.768627451);
  vec3 color2 = vec3(0.);
  vec3 color = mix(color1, color2, uColorProgress);
  gl_FragColor = vec4(color, 1.);
}
`
class Geometry extends THREE.BufferGeometry {
  constructor() {
    super()

    this.progress = 0

    const vertices = new Float32Array([
      -1, -1, 1,
      1, -1, 1,
      1, 1, 1,

      1, 1, 1,
      -1, 1, 1,
      -1, -1, 1,
    ]);

    this.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

  }

  update(progress) {

    this.progress = progress

    this.attributes.position.needsUpdate = true;

  }
}

export class FSBackground {

  constructor(scene) {
    this.scene = scene

    this.node = new THREE.Object3D()
    this.scene.scene.add(this.node)

    this.colorProgress = 0
    this.tgtColorProgress = 0

    this.geom = new Geometry()
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uColorProgress: { value: 0 }
      },
      vertexShader: vs,
      fragmentShader: fs
    })

    this.mesh = new THREE.Mesh(this.geom, material)
    this.mesh.frustumCulled = false

    // this.node.add(this.mesh)

  }

  update() {
    this.colorProgress += (this.tgtColorProgress - this.colorProgress) * .1

    this.mesh.material.uniforms.uColorProgress.value = this.colorProgress

  }
}