export const IMAGES_TYPES = {
    cover: {
        mobile: 480,
        desktop: 1115
    },
    aboutClients: {
        mobile: 205,
        desktop: 205
    },
    carousel: {
        mobile: 480,
        desktop: 1115
    },
    column: {
        mobile: 480,
        desktop: 660
    },
    composition: {
        mobile: 480,
        desktop: 364
    },
    compositionAlt: {
        mobile: 480,
        desktop: 660
    },
    footer: {
        mobile: 480,
        desktop: 364
    },
    image: {
        mobile: 480,
        desktop: 1115
    },
    video: {
        mobile: 480,
        desktop: 1380
    }
}