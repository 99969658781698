import actions from './actions';
import mutations from './mutations';

const state = {
    currentScroll: 0,
    incrementWheel: 0,
    limitScroll: { x: 0, y: 0 },
    isSmooth: false
};

const store = {
    namespaced: true,
    state,
    actions,
    mutations,
};

export default store;
