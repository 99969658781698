const mutations = {
    SET_FONTS_LOADED: (state, val) => {
        state.isFontsLoaded = val;
    },
    SET_DATA_LOADED: (state, val) => {
        state.isDataLoaded = val;
    },
    SET_IMAGES_LOADED: (state, val) => {
        state.isImagesLoaded = val;
    },
    SET_ACTIVE_VIMEO_ID: (state, val) => {
        state.activeVimeoId = val;
    },
    FORCE_SCROLL_UPDATE: (state) => {
        state.scrollUpdate += 1;
    },
    SET_PAGE_ENTERING: (state, val) => {
        state.isPageEntering = val;
    }
};

export default mutations;
