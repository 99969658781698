<template>
    <div class="c-menu">
        <nav class="c-nav" aria-label="Principale">
            <ul class="c-nav_list">
                <li>
                    <router-link
                        class="c-nav_link"
                        to="/"
                        aria-label="work"
                        data-cursor='hover'
                    >
                        <span class="c-nav_link_label">
                            Work
                        </span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        class="c-nav_link"
                        to="/about"
                        aria-label="About"
                        data-cursor='hover'
                    >
                        <span class="c-nav_link_label">
                            About
                        </span>
                    </router-link>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "Menu"
};
</script>